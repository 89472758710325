import React, {useEffect, useState} from "react";
import {
    Button, ButtonDropdown,
    ColumnLayout,
    Container,
    FormField,
    Header, Icon,
    Input, Select, SpaceBetween, Spinner,
    Wizard
} from "@amzn/awsui-components-react";
import {WorkflowCondition} from "../../dem-api/generated-src";
import {GetDemWorkflowTriggerConditions, SubscribeDemWorkflow} from "../../dem-api/DemApiFactory";

const FIELD = "field";
const FIELD_TYPE = "field_type";
const FIELD_CONDITION = "field_condition";
const FIELD_VALUE = "field_value";

const WORKFLOW_NAME = "workflowName";
const WORKFLOW_DESCRIPTION = "workflowDescription";
const WORKFLOW_DEFAULT_TRIGGER_ID = "workflowDefaultTriggerId";
const FOLDER_ID_FOR_WORKFLOW_SUBSCRIPTION = "folderIdForWorkflowSubscription";


const WORKFLOW_SUBSCRIBING = "Subscribing";
const WORKFLOW_SUBSCRIBED = "Subscribed";
const WORKFLOW_SUBSCRIPTION_ERROR = "Error";

// constants for options under field drop down in workflow conditions
const FIELD_CREATE_DATE = "create_date";
const FIELD_DESCRIPTION = "description";
const FIELD_SEVERITY = "severity";
const FIELD_TITLE = "title";

// constants for options under field type drop down in workflow conditions
const FIELD_TYPE_DATE = "date";
const FIELD_TYPE_NUMBER = "number";
const FIELD_TYPE_TEXT = "text";

// constants for options under condition drop down in workflow conditions
const CONDITION_IS_BEFORE = "is_before";
const CONDITION_IS_ON_OR_BEFORE = "is_on_or_before";
const CONDITION_IS_EQUAL_TO = "is_equal_to";
const CONDITION_IS_AFTER = "is_after";
const CONDITION_IS_ON_OR_AFTER = "is_on_or_after";
const CONDITION_IS_LESS_THAN = "is_less_than";
const CONDITION_IS_LESS_THAN_OR_EQUAL_TO = "is_less_than_or_equal_to";
const CONDITION_IS_GREATER_THAN = "is_greater_than";
const CONDITION_IS_GREATER_THAN_OR_EQUAL_TO = "is_greater_than_or_equal_to";
const CONDITION_STARTS_WITH = "starts_with";
const CONDITION_CONTAINS = "contains";
const CONDITION_NOT_CONTAINS = "not_contains";
const CONDITION_IS_NOT_EQUAL_TO = "is_not_equal_to";
const CONDITION_ENDS_WITH = "ends_with";
const ERROR = "error";
const WORKFLOW_CONDITIONS = "workflow_conditions";
export default function WorkflowContent(props) {
    // stores the properties associated with workflow
    let workflowProperties = props.data;
    // stores the name of workflow
    let workflowName = workflowProperties[WORKFLOW_NAME];
    // stores the description of workflow
    let workflowDescription = workflowProperties[WORKFLOW_DESCRIPTION];
    // stores the default trigger id for this workflow
    let workflowDefaultTriggerId = workflowProperties[WORKFLOW_DEFAULT_TRIGGER_ID];
    // stores the default trigger id for this workflow
    let folderIdForWorkflowSubscription = workflowProperties[FOLDER_ID_FOR_WORKFLOW_SUBSCRIPTION];
    // Stores the state for workflow conditions
    const [workflowConditions, setWorkflowConditions] = useState<WorkflowCondition[]>([]);

    // stores the state for specifying whether the default workflow conditions are fetched or not
    const [isDefaultWorkflowConditionFetched, setIsDefaultWorkflowConditionFetched] = useState(false);

    // stores the state of active step index
    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);

    // stores the state of workflows subscription status
    const [workFlowsSubscriptionStatus, setWorkFlowsSubscriptionStatus] = React.useState("");
    // stores the instance of api to subscribe dem workflows
    const subscribeDemWorkflowApi = SubscribeDemWorkflow();

    /**
     * Subscribe the DEM workflow by making the necessary entries in the required DB tables and shows the subscription
     * status on UI based on the result of subscribe dem workflow api
     */
    const showWorkFlowsSubscribedStatus = () => {
        setWorkFlowsSubscriptionStatus(WORKFLOW_SUBSCRIBING);
        subscribeDemWorkflowApi.subscribedemworkflow({
            folderId: folderIdForWorkflowSubscription,
            workflowName: workflowName,
            workflowConditions: workflowConditions
        }).then((response) => {
            let workflowSubscriptionOutput = Boolean(response.data);
            workflowSubscriptionOutput ? setWorkFlowsSubscriptionStatus(WORKFLOW_SUBSCRIBED) : setWorkFlowsSubscriptionStatus(WORKFLOW_SUBSCRIPTION_ERROR);
        });
    };

    /**
     * Sets the workflow conditions to default trigger conditions by parsing them based on the workflow default trigger id.
     */
    useEffect(() => {
        void (async () => {
            setWorkflowConditions(await parseWorkflowTriggerConditions());
        })();
    }, [workflowDefaultTriggerId]);

    /**
     * Parses the default workflow trigger conditions by fetching the same from API - GetDemWorkflowTriggerConditions
     * using the default trigger id (if any) attached with the current workflow.
     */
    async function parseWorkflowTriggerConditions() {
        setIsDefaultWorkflowConditionFetched(false);
        // stores the instance of api to subscribe dem workflows
        const getDemWorkflowTriggerConditionsApi = GetDemWorkflowTriggerConditions();
        let defaultWorkflowConditionsResponse = (await getDemWorkflowTriggerConditionsApi.getWorkflowTriggerConditions(workflowDefaultTriggerId)).data;
        let defaultWorkflowConditions = defaultWorkflowConditionsResponse[WORKFLOW_CONDITIONS];
        let workflowConditionsFetchingError = defaultWorkflowConditionsResponse[ERROR]
        if (defaultWorkflowConditions.length <= 0) {
            // log the error in case if any occurred while fetching the default workflow conditions
            if (workflowConditionsFetchingError !== ""){
                console.log(workflowConditionsFetchingError)
            }
            defaultWorkflowConditions = [{
                field: FIELD_SEVERITY,
                field_type: FIELD_TYPE_NUMBER,
                condition: CONDITION_IS_EQUAL_TO,
                param: "3",
                condition_type: ""
            }];
        }
        setIsDefaultWorkflowConditionFetched(true);
        return defaultWorkflowConditions;
    }

    /**
     * Returns the title case for the passed-in string value using the passed-in delimiter between two words
     * @param stringValue Specifies the string value whose title case is to be returned
     * @param delimiter Specifies the delimiter present between the words
     */
    function toTitleCase(stringValue: string | null | undefined, delimiter: string) {
        return stringValue?.split(delimiter).map((s: string) => s[0].toUpperCase() + s.substr(1)).join(" ")
    }

    /**
     * Sets the drop-down options for condition clause under the workflow conditions based on the passed-in field type
     * @param field_type specifies the field type i.e. either date or number or text.
     */
    function setWorkflowConditionOptions(field_type: string | undefined) {
        switch (field_type) {
            case "date": {
                return [
                    {label: toTitleCase(CONDITION_IS_BEFORE, "_"), value: CONDITION_IS_BEFORE},
                    {label: toTitleCase(CONDITION_IS_ON_OR_BEFORE, "_"), value: CONDITION_IS_ON_OR_BEFORE},
                    {label: toTitleCase(CONDITION_IS_EQUAL_TO, "_"), value: CONDITION_IS_EQUAL_TO},
                    {label: toTitleCase(CONDITION_IS_AFTER, "_"), value: CONDITION_IS_AFTER},
                    {label: toTitleCase(CONDITION_IS_ON_OR_AFTER, "_"), value: CONDITION_IS_ON_OR_AFTER}
                ];
            }
            case "number": {
                return [
                    {label: toTitleCase(CONDITION_IS_LESS_THAN, "_"), value: CONDITION_IS_LESS_THAN},
                    {
                        label: toTitleCase(CONDITION_IS_LESS_THAN_OR_EQUAL_TO, "_"),
                        value: CONDITION_IS_LESS_THAN_OR_EQUAL_TO
                    },
                    {label: toTitleCase(CONDITION_IS_EQUAL_TO, "_"), value: CONDITION_IS_EQUAL_TO},
                    {label: toTitleCase(CONDITION_IS_GREATER_THAN, "_"), value: CONDITION_IS_GREATER_THAN},
                    {
                        label: toTitleCase(CONDITION_IS_GREATER_THAN_OR_EQUAL_TO, "_"),
                        value: CONDITION_IS_GREATER_THAN_OR_EQUAL_TO
                    }
                ];
            }
            default: {
                return [
                    {label: toTitleCase(CONDITION_STARTS_WITH, "_"), value: CONDITION_STARTS_WITH},
                    {label: toTitleCase(CONDITION_CONTAINS, "_"), value: CONDITION_CONTAINS},
                    {label: toTitleCase(CONDITION_NOT_CONTAINS, "_"), value: CONDITION_NOT_CONTAINS},
                    {label: toTitleCase(CONDITION_IS_EQUAL_TO, "_"), value: CONDITION_IS_EQUAL_TO},
                    {label: toTitleCase(CONDITION_IS_NOT_EQUAL_TO, "_"), value: CONDITION_IS_NOT_EQUAL_TO},
                    {label: toTitleCase(CONDITION_ENDS_WITH, "_"), value: CONDITION_ENDS_WITH}
                ];
            }
        }
    }

    /**
     * Sets the condition clause selected option for the passed-in workflow condition based on the passed-in condition
     * clause and field type
     * @param condition Specifies the workflow condition under which selected option for passed-in conditionClause needs
     * to be updated
     * @param conditionClause Specifies the condition clause whose selected option needs to be updated
     * @param fieldType Specifies the field type, used only in case when drop down options for conditions needs to be
     * updated as per the selected field type
     */
    function setConditionClauseSelectedOption(condition: WorkflowCondition, conditionClause: string, fieldType: string | undefined) {
        let indexToFetchCondition = workflowConditions.indexOf(condition);
        let selectedValue: string | null | undefined = "";
        switch (conditionClause) {
            case FIELD: {
                selectedValue = workflowConditions[indexToFetchCondition].field;
                if (selectedValue === "") {
                    selectedValue = FIELD_CREATE_DATE;
                }
                break;
            }
            case FIELD_TYPE: {
                selectedValue = workflowConditions[indexToFetchCondition].field_type;
                if (selectedValue === "") {
                    selectedValue = FIELD_TYPE_DATE;
                }
                break;
            }
            case FIELD_CONDITION: {
                if (fieldType === undefined) {
                    selectedValue = workflowConditions[indexToFetchCondition].condition;
                    if (selectedValue === "") {
                        selectedValue = CONDITION_IS_BEFORE;
                    }
                } else {
                    selectedValue = setWorkflowConditionOptions(fieldType)[0].value;
                    workflowConditions[indexToFetchCondition].condition = selectedValue;
                    setWorkflowConditions([...workflowConditions]);
                }
                break;
            }
        }
        let selectedLabelValue = toTitleCase(selectedValue, "_");
        return {label: selectedLabelValue, value: selectedValue};
    }

    /**
     * Updates the (passed-in) value under the (passed-in) clause of (passed-in) condition
     * @param condition Specifies the condition which needs to be updated
     * @param conditionClause Specifies the clause of condition whose value needs to be updated
     * @param value Speciifies the updated value
     */
    function updateCondition(condition: WorkflowCondition, conditionClause: string, value: string | undefined) {
        let indexToUpdate = workflowConditions.indexOf(condition)
        switch (conditionClause) {
            case FIELD: {
                workflowConditions[indexToUpdate].field = value;
                break;
            }
            case FIELD_TYPE: {
                workflowConditions[indexToUpdate].field_type = value;
                setConditionClauseSelectedOption(condition, FIELD_CONDITION, value);
                break;
            }
            case FIELD_CONDITION: {
                workflowConditions[indexToUpdate].condition = value;
                break;
            }
            case FIELD_VALUE: {
                workflowConditions[indexToUpdate].param = value;
                break;
            }
            default: {
                workflowConditions[indexToUpdate] = condition;
            }
        }
        setWorkflowConditions([...workflowConditions]);
    }

    /**
     * Adds an empty condition with the passed-in condition type
     * @param conditionType Specifies the condition type to be added with empty condition
     */
    function addCondition(conditionType: string) {
        workflowConditions.push({
            field: "",
            field_type: "",
            condition: "",
            param: "",
            condition_type: conditionType
        })
        setWorkflowConditions([...workflowConditions]);
    }

    /**
     * Removes the passed-in workflow condition
     * @param condition Specifies the condition to be removed
     */
    function removeCondition(condition: WorkflowCondition) {
        let indexToRemove = workflowConditions.indexOf(condition)
        if (indexToRemove !== -1) {
            // deletes the condition from indexToRemove in workflow conditions array
            workflowConditions.splice(indexToRemove, 1);
        }
        setWorkflowConditions([...workflowConditions]);
    }

    return (
        <div>
            <br/>
            <Container header={
                <Header
                    variant="h3"
                >
                    <b>{workflowName} Configuration</b>
                </Header>
            }>
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Section ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Section ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) =>
                            `Skip to ${step.title}`,
                        navigationAriaLabel: "Sections",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Submit Changes",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) => {
                        setActiveStepIndex(detail.requestedStepIndex)
                        setWorkFlowsSubscriptionStatus("");
                    }}
                    onSubmit={() => {
                        showWorkFlowsSubscribedStatus();
                    }}
                    activeStepIndex={activeStepIndex}
                    steps={[
                        {
                            title: "Workflow Information",
                            content: (
                                <p>
                                    <b style={{color: "darkgray"}}>Target Workflow</b> : DWF_{workflowName}
                                    <div>
                                        {workflowDescription}
                                    </div>
                                </p>
                            ),
                        },
                        {
                            title: "Add Conditions",
                            content: (
                                <div>
                                    {isDefaultWorkflowConditionFetched ?
                                        <SpaceBetween size="l" direction="vertical">
                                            {workflowConditions.map(condition => {
                                                    return <div>
                                                        {condition.condition_type ?
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    height: "10px",
                                                                    borderBottom: "1px solid lightgray",
                                                                    textAlign: "center"
                                                                }}>
                                            <span style={{
                                                fontSize: "15px", fontStyle: "italic",
                                                backgroundColor: "white", padding: "30px", color: "gray"
                                            }}>{condition.condition_type}</span><br/></div> : ""}
                                                        <br/>
                                                        <ColumnLayout columns={5}>
                                                            <div style={{width: "125px"}}>
                                                                <FormField label="Field">
                                                                    <Select
                                                                        selectedOption={setConditionClauseSelectedOption(condition, FIELD, undefined)}
                                                                        onChange={({detail}) =>
                                                                            updateCondition(condition, FIELD, detail.selectedOption.value)
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label: toTitleCase(FIELD_CREATE_DATE, "_"),
                                                                                value: FIELD_CREATE_DATE
                                                                            },
                                                                            {
                                                                                label: toTitleCase(FIELD_DESCRIPTION, "_"),
                                                                                value: FIELD_DESCRIPTION
                                                                            },
                                                                            {
                                                                                label: toTitleCase(FIELD_SEVERITY, "_"),
                                                                                value: FIELD_SEVERITY
                                                                            },
                                                                            {
                                                                                label: toTitleCase(FIELD_TITLE, "_"),
                                                                                value: FIELD_TITLE
                                                                            }
                                                                        ]}
                                                                        selectedAriaLabel="Selected"
                                                                    />
                                                                </FormField>
                                                            </div>
                                                            <div style={{width: "125px"}}>
                                                                <FormField label="Field Type">
                                                                    <Select
                                                                        selectedOption={setConditionClauseSelectedOption(condition, FIELD_TYPE, undefined)}
                                                                        onChange={({detail}) =>
                                                                            updateCondition(condition, FIELD_TYPE, detail.selectedOption.value)
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label: toTitleCase(FIELD_TYPE_DATE, "_"),
                                                                                value: FIELD_TYPE_DATE
                                                                            },
                                                                            {
                                                                                label: toTitleCase(FIELD_TYPE_NUMBER, "_"),
                                                                                value: FIELD_TYPE_NUMBER
                                                                            },
                                                                            {
                                                                                label: toTitleCase(FIELD_TYPE_TEXT, "_"),
                                                                                value: FIELD_TYPE_TEXT
                                                                            }
                                                                        ]}
                                                                        selectedAriaLabel="Selected"
                                                                    />
                                                                </FormField>
                                                            </div>
                                                            <div style={{width: "220px"}}>
                                                                <FormField label="Condition">
                                                                    <Select
                                                                        selectedOption={setConditionClauseSelectedOption(condition, FIELD_CONDITION, undefined)}
                                                                        onChange={({detail}) =>
                                                                            updateCondition(condition, FIELD_CONDITION, detail.selectedOption.value)
                                                                        }
                                                                        options={setWorkflowConditionOptions(condition.field_type)}
                                                                        selectedAriaLabel="Selected"
                                                                    />
                                                                </FormField>
                                                            </div>
                                                            <div style={{width: "270px"}}>
                                                                <FormField label="Value">
                                                                    <Input value={condition.param ? condition.param : ""}
                                                                           onChange={event =>
                                                                               updateCondition(condition, FIELD_VALUE, event.detail.value)
                                                                           }/>
                                                                </FormField>
                                                            </div>
                                                            {workflowConditions.indexOf(condition) !== 0 ?
                                                                <div style={{paddingTop: 24}}>
                                                                    <Button onClick={() => {
                                                                        removeCondition(condition)
                                                                    }
                                                                    }>Remove</Button>
                                                                </div> : ""}
                                                        </ColumnLayout>
                                                    </div>
                                                }
                                            )}
                                            <ButtonDropdown
                                                items={[
                                                    {text: "AND", id: "and", disabled: false},
                                                    {text: "OR", id: "or", disabled: false},
                                                    {text: "NOT", id: "not", disabled: false},
                                                ]}

                                                onItemClick={event => {
                                                    addCondition(event.detail.id)
                                                }}
                                            >
                                                Add Condition
                                            </ButtonDropdown>
                                        </SpaceBetween>
                                        : <div style={{margin: "auto"}}>
                                            <Spinner size="normal"/>
                                            Fetching default workflow conditions...
                                        </div>
                                    }
                                </div>
                            ),
                        }
                    ]}
                />
                {workFlowsSubscriptionStatus === WORKFLOW_SUBSCRIBED ? (
                    <div id="demSuccess">
                        <Icon id="successIcon" name="status-positive" variant="success"/>
                        Subscribed to {workflowName}
                    </div>
                ) : workFlowsSubscriptionStatus === WORKFLOW_SUBSCRIPTION_ERROR ? (
                    <div id="demError">
                        <Icon id="errorIcon" name="status-negative" variant="error"/>
                        An error occurred while subscribing to {workflowName}. Please try again!
                    </div>
                ) : workFlowsSubscriptionStatus === WORKFLOW_SUBSCRIBING ? (
                    <div style={{margin: "auto"}}>
                        <Spinner size="normal"/>
                        Subscribing {workflowName}...
                    </div>
                ) : (
                    <></>
                )}
            </Container>
        </div>
    );
}
