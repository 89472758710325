import PageHeader from './PageHeader';
import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Grid, Icon,
    Input,
    Modal,
    SpaceBetween, Wizard
} from "@amzn/awsui-components-react";
import HomeContent from "./HomeContent";
import React, {useState} from "react";
import {OnboardToDem} from "../../dem-api/DemApiFactory";

export default function Home() {
    const [onboardModalView, setOnboardModalView] = React.useState(false);
    const [simFolderId, setSimFolderId] = useState("");
    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);

    const OnboardToDemApi = OnboardToDem();
    const [onboardedToDem, setOnboardedToDem] = React.useState("toBeDetermined");
    const [validatedSimFolder, setValidatedSimFolder] = React.useState(true);

    function onboardToDem() {
        OnboardToDemApi.onboardtodem({
            label: simFolderId,
            value: simFolderId
        }).then((response) => {
            console.log(response.data);
            response.data ? setOnboardedToDem("valid") : setOnboardedToDem("invalid");
        });
    }
    function checkValidationStatus(){
        if(onboardedToDem==="valid")
        {
            return <div id="demSuccess">
                <Icon id="successIcon" name="status-positive" variant="success" />
                Prerequisite Validation Successful
            </div>
        }
        else if(onboardedToDem==="invalid")
        {
            return <div id="demFailure">
                <Icon id="failureIcon" name="status-negative" variant="error" />
                Prerequisite Validation Failed
            </div>
        }
        else
        {
            return <></>
        }
    }
    function validateSimFolderId(simFolder){
        let expression: RegExp = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
        if(expression.test(simFolder) && simFolder.length===36)
        {
            setValidatedSimFolder(true);
            return true
        }
        else
        {
            setValidatedSimFolder(false);
            return false
        }
    }

    return (
        <div>
            <Modal size="large"
                   onDismiss={() => setOnboardModalView(false)}
                   visible={onboardModalView}
                   closeAriaLabel="Close modal"
                   header="Steps to complete the Prerequisite validation"
            >
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber =>
                            `Step ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) =>
                            `Step ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) =>
                            `Skip to ${step.title}`,
                        navigationAriaLabel: "Steps",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Validate",
                        optional: "optional"
                    }}
                    onNavigate={({detail}) => {
                        if (validateSimFolderId(simFolderId))
                        {
                            setActiveStepIndex(detail.requestedStepIndex);
                        }
                    }
                    }
                    onSubmit={() => {
                        onboardToDem();
                    }}
                    onCancel={() => {
                        setOnboardModalView(false);
                    }}
                    activeStepIndex={activeStepIndex}
                    steps={[
                        {
                            title: "Provide SIM Folder ID",
                            description:
                                "Each resolver group / Sim folder has 36 character alphanumeric id which is needed by DEM Workflow to read and update tickets",
                            content: (
                                <FormField label="SIM Folder ID">
                                    <Input value={simFolderId} onChange={event => {
                                        setSimFolderId(event.detail.value.trim())
                                    }}/>
                                </FormField>
                            )
                        },
                        {
                            title: "Add DEM as Watcher",
                            content: (
                                <p>
                                    Add this SNS topic as a watcher to the SIM folder specified in previous step
                                    <h3>arn:aws:sns:us-west-2:855930054778:workflow-trigger-sns-topic</h3>

                                    Add this SIM role under the permissions section of the folder
                                    <h3>arn:aws:iam::855930054778:role/WorkflowTriggerMaxisRole</h3>
                                </p>
                            ),
                        }
                    ]}
                />
                {
                    checkValidationStatus()
                }
                {
                    validatedSimFolder ? (
                        <></>
                    ):
                        (
                            <div id="demFailure">
                                <Icon id="failureIcon" name="status-negative" variant="error" />
                                Invalid SIM Folder Id
                            </div>
                        )
                }
            </Modal>
            <PageHeader/>
            <div style={{background: "rgba(10,105,161,255)", padding: 7, height: 150}}>
                <Box textAlign="center" margin={{left: "xl", bottom: "xl", right: "xl"}}
                     padding={{left: "xl", bottom: "xl", right: "xl"}}>
                    <Grid
                        gridDefinition={[{colspan: 3}, {colspan: 6}, {colspan: 3}]}
                    >
                        <div></div>
                        <div>
                            <ColumnLayout columns={2}>
                                <div style={{textAlign: "center", color: "white"}}>
                                    <h1 style={{textAlign: "left"}}>Alexa DEM Workflow UI</h1>
                                    <p style={{textAlign: "left"}}>Create powerful Workflows to automate your Event
                                        mitigation strategies or Run-Books</p>
                                </div>
                                <div id="slideRightToLeftOnPageLoad" style={{textAlign: "right", paddingTop: "15px"}}>
                                    <Button onClick={e => {
                                        setOnboardModalView(true);
                                    }} variant="primary">
                                        Prerequistes for DEM Onboarding
                                    </Button>
                                </div>
                            </ColumnLayout>
                        </div>
                        <div></div>
                    </Grid>
                </Box>
            </div>
            <div>
                <HomeContent/>
            </div>

        </div>
    );
}
