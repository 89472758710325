import {
    Badge,
    Button,
    ColumnLayout,
    Grid,
    Icon,
    Select,
    SelectProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import StarPicker from "react-star-picker";
import {BsFillBookmarkFill} from "react-icons/bs"
import DemApiFactory from "../../dem-api/DemApiFactory";
import {SimFolder} from "../../dem-api/generated-src";

export default function WorkFlowHomePage() {
    const [simFolders, setSimFolders] = useState<SimFolder[]>();
    const [
        selectedSIMFolder,
        setSelectedSIMFolder
    ] = useState<SelectProps.Option>({});

    const demGetFoldersApi = DemApiFactory();

    useEffect(() => {
        (async () => {
            setSimFolders((await (demGetFoldersApi.getSimFolders())).data);
        })();
    }, []);

    return (
        <div>
            <ColumnLayout borders="horizontal">
                <div style={{width: "500px"}}>
                    <h4>Workflows onboarded to SIM Folder :</h4>
                    <Select
                        selectedOption={selectedSIMFolder}
                        onChange={({detail}) =>
                            setSelectedSIMFolder(detail.selectedOption)
                        }
                        // ADD Folder Id as value during backend implementation
                        options={simFolders?.map(item => ({
                            label: item.label,
                            value: item.value
                        }))}
                        selectedAriaLabel="Selected"
                    />
                </div>
                <h2>Workflows</h2>
                <div>
                    <Grid gridDefinition={[{colspan: 0.5}, {colspan: 6}, {colspan: 4.5}]}>
                        <div style={{paddingTop: "4px"}}>
                            <svg className="w-6 h-6" height="40" width="40" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient x1="0%" y1="100%" x2="100%" y2="0%"
                                                    id="Arch_AWS-Express-Workflow_32_svg__a">
                                        <stop stop-color="#B0084D" offset="0%"></stop>
                                        <stop stop-color="#FF4F8B" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M0 0h40v40H0z" fill="url(#Arch_AWS-Express-Workflow_32_svg__a)"></path>
                                    <path
                                        d="M31.849 22h-9.965l1.072-4h9.965l-1.072 4zm1.724-5h-11a.5.5 0 00-.484.371l-1.34 5a.501.501 0 00.484.629h11a.5.5 0 00.483-.371l1.34-5a.498.498 0 00-.483-.629zm-12.17 14.5c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5.673-1.5 1.5-1.5 1.5.673 1.5 1.5zM6.152 24l.537-2h8.965l-.537 2H6.152zm0-6l.537-2h8.964l-.536 2H6.152zm12.25-9.5c0-.827.674-1.5 1.5-1.5.828 0 1.5.673 1.5 1.5s-.672 1.5-1.5 1.5c-.826 0-1.5-.673-1.5-1.5zm9 18.5h-16v-2H15.5a.502.502 0 00.484-.371l.804-3a.5.5 0 00-.483-.629h-4.902v-2H15.5a.502.502 0 00.484-.371l.804-3a.5.5 0 00-.483-.629h-4.902v-2h16v2h1v-2.5a.5.5 0 00-.5-.5h-7.5v-1.051a2.504 2.504 0 002-2.449c0-1.378-1.121-2.5-2.5-2.5a2.503 2.503 0 00-2.5 2.5c0 1.207.86 2.217 2 2.449V12h-8.5a.5.5 0 00-.5.5V15H6.305a.5.5 0 00-.483.371l-.805 3A.498.498 0 005.5 19h4.903v2H6.305a.5.5 0 00-.483.371l-.805 3A.498.498 0 005.5 25h4.903v2.5a.5.5 0 00.5.5h8.5v1.051c-1.14.232-2 1.242-2 2.449 0 1.378 1.12 2.5 2.5 2.5 1.379 0 2.5-1.122 2.5-2.5a2.504 2.504 0 00-2-2.449V28h7.5a.5.5 0 00.5-.5V25h-1v2z"
                                        fill="#FFF"></path>
                                </g>
                            </svg>
                        </div>
                        <div>

                            <div>
                                <SpaceBetween size="s" direction="horizontal">
                                    <b style={{fontSize: "medium"}}>Redfort Automation</b>
                                    <StarPicker
                                        // @ts-expect-error bad component typing
                                        starRenderer={undefined}
                                        disabled={false}
                                        onChange={(value) => {
                                        }}
                                        value={4}
                                        halfStars={false}
                                        numberStars={5}
                                        size={12}
                                    />(30)
                                </SpaceBetween>
                            </div>
                            <p>This is a subscribable workflow in S&S category, created by AXES team</p>
                            <SpaceBetween size="xs" direction="horizontal">
                                <Badge>Redfort</Badge>
                                <Badge>AXES</Badge>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size="xs" direction="horizontal">
                                <div style={{padding: "5px"}}>
                                    <Button variant="normal">Unsubscribe <Icon name="notification"
                                                                               variant="normal"/></Button>
                                </div>
                                <div><Button iconName="download" variant="icon"/></div>
                                <div><Button iconName="group-active" variant="icon"/> 21</div>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </div>
                <div>
                    <Grid gridDefinition={[{colspan: 0.5}, {colspan: 6}, {colspan: 4.5}]}>
                        <div style={{paddingTop: "4px"}}><BsFillBookmarkFill size={16} color="lightSkyBlue"/></div>
                        <div>
                            <div>
                                <SpaceBetween size="s" direction="horizontal">
                                    <b style={{fontSize: "medium"}}>Atocha Aggregation Workflow</b>
                                    <StarPicker
                                        // @ts-expect-error bad component typing
                                        starRenderer={undefined}
                                        disabled={false}
                                        onChange={(value) => {
                                        }}
                                        value={3}
                                        halfStars={false}
                                        numberStars={5}
                                        size={12}
                                    />(12)
                                </SpaceBetween>
                            </div>
                            <p>This is a configurable workflow, created by A&M Team</p>
                            <SpaceBetween size="xs" direction="horizontal">
                                <Badge>Atocha</Badge>
                                <Badge>AXES</Badge>
                                <Badge>logging</Badge>
                                <Badge>query</Badge>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size="xs" direction="horizontal">
                                <div style={{padding: "5px"}}>
                                    <Button variant="primary">Edit Configuration</Button>
                                </div>
                                <div><Button iconName="download" variant="icon"/></div>
                                <div><Button iconName="group-active" variant="icon"/> 15</div>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </div>
            </ColumnLayout>
            <br/><br/><br/>
            <ColumnLayout borders="horizontal">
                <h2>Other Workflows you can subscribe to</h2>
                <div>
                    <Grid gridDefinition={[{colspan: 0.5}, {colspan: 6}, {colspan: 4.5}]}>
                        <div style={{paddingTop: "4px"}}><BsFillBookmarkFill size={16} color="orange"/></div>
                        <div>
                            <div>
                                <SpaceBetween size="s" direction="horizontal">
                                    <b style={{fontSize: "medium"}}>Deduped Workflow</b>
                                    <StarPicker
                                        // @ts-expect-error bad component typing
                                        starRenderer={undefined}
                                        disabled={false}
                                        onChange={(value) => {
                                        }}
                                        value={3}
                                        halfStars={false}
                                        numberStars={5}
                                        size={12}
                                    />(22)
                                </SpaceBetween>
                            </div>
                            <p>This is a team only customizable workflow</p>
                            <SpaceBetween size="xs" direction="horizontal">
                                <Badge>Ticket</Badge>
                                <Badge>AXES</Badge>
                                <Badge>de-dupe</Badge>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween size="xs" direction="horizontal">
                                <div style={{padding: "5px"}}>
                                    <Button variant="primary">Subscribe <Icon name="notification"
                                                                              variant="normal"/></Button>
                                </div>
                                <div><Button iconName="download" variant="icon"/></div>
                                <div><Button iconName="group-active" variant="icon"/> 5</div>
                            </SpaceBetween>
                        </div>
                    </Grid>
                </div>
            </ColumnLayout>
        </div>
    );
}