/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI DEM UI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DemOnDemand
 */
export interface DemOnDemand {
    /**
     * 
     * @type {string}
     * @memberof DemOnDemand
     */
    'ticketid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemOnDemand
     */
    'servicename'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemOnDemand
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemOnDemand
     */
    'datasource'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemOnDemand
     */
    'useticketcreationtime'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface MonitorSchema
 */
export interface MonitorSchema {
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'schema'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'dataset'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'marketplace'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'hostgroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'client'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'metricclass'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'instance'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'metric'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorSchema
     */
    'pattern'?: string;
}
/**
 * 
 * @export
 * @interface SimFolder
 */
export interface SimFolder {
    /**
     * 
     * @type {string}
     * @memberof SimFolder
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimFolder
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface SubscribableDemWorkflowConditions
 */
export interface SubscribableDemWorkflowConditions {
    /**
     * 
     * @type {string}
     * @memberof SubscribableDemWorkflowConditions
     */
    'folderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscribableDemWorkflowConditions
     */
    'workflowName'?: string;
    /**
     * 
     * @type {Array<WorkflowCondition>}
     * @memberof SubscribableDemWorkflowConditions
     */
    'workflowConditions'?: Array<WorkflowCondition>;
}
/**
 * 
 * @export
 * @interface WorkflowCategory
 */
export interface WorkflowCategory {
    /**
     * 
     * @type {string}
     * @memberof WorkflowCategory
     */
    'workflowName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCategory
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCategory
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCategory
     */
    'workflowCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCategory
     */
    'workflowDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCategory
     */
    'workflowDefaultTriggerId'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowCondition
 */
export interface WorkflowCondition {
    /**
     * 
     * @type {string}
     * @memberof WorkflowCondition
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCondition
     */
    'field_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCondition
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCondition
     */
    'param'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowCondition
     */
    'condition_type'?: string;
}

/**
 * DemOnDemandApi - axios parameter creator
 * @export
 */
export const DemOnDemandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Trigger Demand On Demand
         * @param {DemOnDemand} demOnDemand Trigger Demand On Demand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDemonDemand: async (demOnDemand: DemOnDemand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'demOnDemand' is not null or undefined
            assertParamExists('triggerDemonDemand', 'demOnDemand', demOnDemand)
            const localVarPath = `/triggerdemandondemand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demOnDemand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemOnDemandApi - functional programming interface
 * @export
 */
export const DemOnDemandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemOnDemandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Trigger Demand On Demand
         * @param {DemOnDemand} demOnDemand Trigger Demand On Demand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerDemonDemand(demOnDemand: DemOnDemand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerDemonDemand(demOnDemand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemOnDemandApi - factory interface
 * @export
 */
export const DemOnDemandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemOnDemandApiFp(configuration)
    return {
        /**
         * 
         * @summary Trigger Demand On Demand
         * @param {DemOnDemand} demOnDemand Trigger Demand On Demand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDemonDemand(demOnDemand: DemOnDemand, options?: any): AxiosPromise<string> {
            return localVarFp.triggerDemonDemand(demOnDemand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemOnDemandApi - object-oriented interface
 * @export
 * @class DemOnDemandApi
 * @extends {BaseAPI}
 */
export class DemOnDemandApi extends BaseAPI {
    /**
     * 
     * @summary Trigger Demand On Demand
     * @param {DemOnDemand} demOnDemand Trigger Demand On Demand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemOnDemandApi
     */
    public triggerDemonDemand(demOnDemand: DemOnDemand, options?: AxiosRequestConfig) {
        return DemOnDemandApiFp(this.configuration).triggerDemonDemand(demOnDemand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FoldersApi - axios parameter creator
 * @export
 */
export const FoldersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all SIM Folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimFolders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/getSimFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoldersApi - functional programming interface
 * @export
 */
export const FoldersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoldersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all SIM Folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimFolders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimFolder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimFolders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoldersApi - factory interface
 * @export
 */
export const FoldersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoldersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all SIM Folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimFolders(options?: any): AxiosPromise<Array<SimFolder>> {
            return localVarFp.getSimFolders(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FoldersApi - object-oriented interface
 * @export
 * @class FoldersApi
 * @extends {BaseAPI}
 */
export class FoldersApi extends BaseAPI {
    /**
     * 
     * @summary Get all SIM Folders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public getSimFolders(options?: AxiosRequestConfig) {
        return FoldersApiFp(this.configuration).getSimFolders(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetWorkflowTriggerConditionsApi - axios parameter creator
 * @export
 */
export const GetWorkflowTriggerConditionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all workflow trigger conditions associated with passed-in trigger Id
         * @param {string} triggerId Specifies the trigger Id for which workflow conditions are to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowTriggerConditions: async (triggerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'triggerId' is not null or undefined
            assertParamExists('getWorkflowTriggerConditions', 'triggerId', triggerId)
            const localVarPath = `/getWorkflowTriggerConditions/{triggerId}`
                .replace(`{${"triggerId"}}`, encodeURIComponent(String(triggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetWorkflowTriggerConditionsApi - functional programming interface
 * @export
 */
export const GetWorkflowTriggerConditionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetWorkflowTriggerConditionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all workflow trigger conditions associated with passed-in trigger Id
         * @param {string} triggerId Specifies the trigger Id for which workflow conditions are to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowTriggerConditions(triggerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowCondition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowTriggerConditions(triggerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetWorkflowTriggerConditionsApi - factory interface
 * @export
 */
export const GetWorkflowTriggerConditionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetWorkflowTriggerConditionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all workflow trigger conditions associated with passed-in trigger Id
         * @param {string} triggerId Specifies the trigger Id for which workflow conditions are to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowTriggerConditions(triggerId: string, options?: any): AxiosPromise<Array<WorkflowCondition>> {
            return localVarFp.getWorkflowTriggerConditions(triggerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetWorkflowTriggerConditionsApi - object-oriented interface
 * @export
 * @class GetWorkflowTriggerConditionsApi
 * @extends {BaseAPI}
 */
export class GetWorkflowTriggerConditionsApi extends BaseAPI {
    /**
     * 
     * @summary Gets all workflow trigger conditions associated with passed-in trigger Id
     * @param {string} triggerId Specifies the trigger Id for which workflow conditions are to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetWorkflowTriggerConditionsApi
     */
    public getWorkflowTriggerConditions(triggerId: string, options?: AxiosRequestConfig) {
        return GetWorkflowTriggerConditionsApiFp(this.configuration).getWorkflowTriggerConditions(triggerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetWorkflowsApi - axios parameter creator
 * @export
 */
export const GetWorkflowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all workflow categories
         * @param {string} workflowCategory Specifies the category of workflows which are to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows: async (workflowCategory: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowCategory' is not null or undefined
            assertParamExists('getWorkflows', 'workflowCategory', workflowCategory)
            const localVarPath = `/getWorkflows/{workflowCategory}`
                .replace(`{${"workflowCategory"}}`, encodeURIComponent(String(workflowCategory)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetWorkflowsApi - functional programming interface
 * @export
 */
export const GetWorkflowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetWorkflowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all workflow categories
         * @param {string} workflowCategory Specifies the category of workflows which are to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflows(workflowCategory: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflows(workflowCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetWorkflowsApi - factory interface
 * @export
 */
export const GetWorkflowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetWorkflowsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all workflow categories
         * @param {string} workflowCategory Specifies the category of workflows which are to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows(workflowCategory: string, options?: any): AxiosPromise<Array<WorkflowCategory>> {
            return localVarFp.getWorkflows(workflowCategory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetWorkflowsApi - object-oriented interface
 * @export
 * @class GetWorkflowsApi
 * @extends {BaseAPI}
 */
export class GetWorkflowsApi extends BaseAPI {
    /**
     * 
     * @summary Get all workflow categories
     * @param {string} workflowCategory Specifies the category of workflows which are to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetWorkflowsApi
     */
    public getWorkflows(workflowCategory: string, options?: AxiosRequestConfig) {
        return GetWorkflowsApiFp(this.configuration).getWorkflows(workflowCategory, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnboardToDemApi - axios parameter creator
 * @export
 */
export const OnboardToDemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Onboard SIM to DEM
         * @param {SimFolder} simFolder Onboard SIM to DEM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardtodem: async (simFolder: SimFolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'simFolder' is not null or undefined
            assertParamExists('onboardtodem', 'simFolder', simFolder)
            const localVarPath = `/onboardtodem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simFolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardToDemApi - functional programming interface
 * @export
 */
export const OnboardToDemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnboardToDemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Onboard SIM to DEM
         * @param {SimFolder} simFolder Onboard SIM to DEM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardtodem(simFolder: SimFolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardtodem(simFolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnboardToDemApi - factory interface
 * @export
 */
export const OnboardToDemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnboardToDemApiFp(configuration)
    return {
        /**
         * 
         * @summary Onboard SIM to DEM
         * @param {SimFolder} simFolder Onboard SIM to DEM
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardtodem(simFolder: SimFolder, options?: any): AxiosPromise<string> {
            return localVarFp.onboardtodem(simFolder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnboardToDemApi - object-oriented interface
 * @export
 * @class OnboardToDemApi
 * @extends {BaseAPI}
 */
export class OnboardToDemApi extends BaseAPI {
    /**
     * 
     * @summary Onboard SIM to DEM
     * @param {SimFolder} simFolder Onboard SIM to DEM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardToDemApi
     */
    public onboardtodem(simFolder: SimFolder, options?: AxiosRequestConfig) {
        return OnboardToDemApiFp(this.configuration).onboardtodem(simFolder, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscribeDemWorkflowsApi - axios parameter creator
 * @export
 */
export const SubscribeDemWorkflowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe DEM Workflow
         * @param {SubscribableDemWorkflowConditions} subscribableDemWorkflowConditions Subscribe DEM Workflow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribedemworkflow: async (subscribableDemWorkflowConditions: SubscribableDemWorkflowConditions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribableDemWorkflowConditions' is not null or undefined
            assertParamExists('subscribedemworkflow', 'subscribableDemWorkflowConditions', subscribableDemWorkflowConditions)
            const localVarPath = `/subscribedemworkflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribableDemWorkflowConditions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscribeDemWorkflowsApi - functional programming interface
 * @export
 */
export const SubscribeDemWorkflowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscribeDemWorkflowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Subscribe DEM Workflow
         * @param {SubscribableDemWorkflowConditions} subscribableDemWorkflowConditions Subscribe DEM Workflow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribedemworkflow(subscribableDemWorkflowConditions: SubscribableDemWorkflowConditions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribedemworkflow(subscribableDemWorkflowConditions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscribeDemWorkflowsApi - factory interface
 * @export
 */
export const SubscribeDemWorkflowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscribeDemWorkflowsApiFp(configuration)
    return {
        /**
         * 
         * @summary Subscribe DEM Workflow
         * @param {SubscribableDemWorkflowConditions} subscribableDemWorkflowConditions Subscribe DEM Workflow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribedemworkflow(subscribableDemWorkflowConditions: SubscribableDemWorkflowConditions, options?: any): AxiosPromise<string> {
            return localVarFp.subscribedemworkflow(subscribableDemWorkflowConditions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscribeDemWorkflowsApi - object-oriented interface
 * @export
 * @class SubscribeDemWorkflowsApi
 * @extends {BaseAPI}
 */
export class SubscribeDemWorkflowsApi extends BaseAPI {
    /**
     * 
     * @summary Subscribe DEM Workflow
     * @param {SubscribableDemWorkflowConditions} subscribableDemWorkflowConditions Subscribe DEM Workflow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribeDemWorkflowsApi
     */
    public subscribedemworkflow(subscribableDemWorkflowConditions: SubscribableDemWorkflowConditions, options?: AxiosRequestConfig) {
        return SubscribeDemWorkflowsApiFp(this.configuration).subscribedemworkflow(subscribableDemWorkflowConditions, options).then((request) => request(this.axios, this.basePath));
    }
}


