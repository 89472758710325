    import {ColumnLayout, Container, Grid, Header} from "@amzn/awsui-components-react";
    import React, {useState} from "react";
    import WorkFlowHomePage from "../workflows/WorkflowHomePage";
    import ConfigurableWorkflowPage from "../workflows/ConfigurableWorkflowPage";
    import SubscribableWorkflowPage from "../workflows/SubscribableWorkflowPage";
    import CustomizableWorkflowPage from "../workflows/CustomizableWorkflowPage";
    import TasksPage from "../tasks/TasksPage";
    import {BsFillBookmarkFill} from "react-icons/bs";
    import "../WorkFlowStyles.scss";
    import TriggerDem from "../dem/TriggerDem";
    import Link from "@amzn/awsui-components-react/polaris/link";

    const HaveAnIdeaContainer = () => {
        return (
            <Container header={<Header>Have an idea?</Header>} id="ideaContainer">
                <div>
                    <Link external href="https://w.amazon.com/bin/view/Alexa/Excellence/Availability_and_Monitoring/Projects/DEM/3.0/WorkFlow/Constructs">
                        Available components
                    </Link>
                </div>
                <div>
                    <Link external href="https://w.amazon.com/bin/view/Alexa/Excellence/Availability_and_Monitoring/Projects/DEM_Intro/#HContactUs">
                        Contact us
                    </Link>
                </div>
            </Container>
        );
    };

    export default function HomeContent() {
        const [viewOption, setViewOption] = useState("WorkFlowHome");

        return (
            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 7 }, { colspan: 3 }]}>
                <div style={{padding: "20px"}}>
                    <ColumnLayout borders="horizontal">
                        <div>
                            <a href={"#"} style={{
                                color: (viewOption == "WorkFlowHome") ? "darkorange" : "black",
                                textDecoration: "none"
                            }} onClick={e => {
                                setViewOption("WorkFlowHome")
                            }}><h3>Workflows</h3></a>
                            <div style={{paddingLeft: "15px"}}>
                                <ColumnLayout borders="horizontal">
                                    <div><a href={"#"} style={{
                                        color: (viewOption == "ConfigWorkFlow") ? "darkorange" : "black",
                                        textDecoration: "none"
                                    }} onClick={e => {
                                        setViewOption("ConfigWorkFlow")
                                    }}><b><BsFillBookmarkFill size={12} color="lightSkyBlue"/> Configurable Workflow</b></a></div>
                                    <div><a href={"#"} style={{
                                        color: (viewOption == "SubsWorkFlow") ? "darkorange" : "black",
                                        textDecoration: "none"
                                    }} onClick={e => {
                                        setViewOption("SubsWorkFlow")
                                    }}><b><BsFillBookmarkFill size={12} color="orange"/> Subscribable Workflow</b></a></div>
                                    <div><a href={"#"} style={{
                                        color: (viewOption == "CustWorkFlow") ? "darkorange" : "black",
                                        textDecoration: "none"
                                    }} onClick={e => {
                                        setViewOption("CustWorkFlow")
                                    }}><b><BsFillBookmarkFill size={12} color="gray"/> Customizable Workflow</b></a></div>
                                </ColumnLayout>
                            </div>
                        </div>
                        {/* <div>
                            <a
                                href={"#"}
                                style={{
                                    color: viewOption == "TriggerDem" ? "darkorange" : "black",
                                    textDecoration: "none",
                                }}
                                onClick={(e) => {
                                    setViewOption("TriggerDem");
                                }}
                            >
                                <h3>Trigger Dem</h3>
                            </a>
                        </div> */}
                        <div>
                            <a
                                href={"#"}
                                style={{
                                    color: viewOption == "Tasks" ? "darkorange" : "black",
                                    textDecoration: "none",
                                }}
                                onClick={(e) => {
                                    setViewOption("Tasks");
                                }}
                            >
                                <h3>Tasks</h3>
                            </a>
                        </div>
                    </ColumnLayout>
                </div>
                <div style={{padding: "20px"}}>
                    {(() => {
                        switch (viewOption) {
                            case 'WorkFlowHome':
                                return <WorkFlowHomePage/>
                            case 'ConfigWorkFlow':
                                return <ConfigurableWorkflowPage/>
                            case 'SubsWorkFlow':
                                return <SubscribableWorkflowPage/>
                            case 'CustWorkFlow':
                                return <CustomizableWorkflowPage/>
                            /* case "TriggerDem":
                                return <TriggerDem />; */
                            case "Tasks":
                                 return <TasksPage />;
                            default:
                                return null
                        }
                    })()}
                </div>
                <div>
                    <HaveAnIdeaContainer />
                </div>
            </Grid>
        );
    }
