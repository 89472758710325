import {getAppSetting} from "../config/AppSettings";
import {
    FoldersApi,
    OnboardToDemApi,
    DemOnDemandApi,
    SubscribeDemWorkflowsApi,
    GetWorkflowsApi,
    GetWorkflowTriggerConditionsApi
} from "./generated-src";

export default function() {
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return new FoldersApi(undefined, apiBasePath);
}

export function OnboardToDem(){
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return new OnboardToDemApi(undefined, apiBasePath);
}

export function TriggerDemOnDemand(){
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return new DemOnDemandApi(undefined, apiBasePath);
}

export function SubscribeDemWorkflow(){
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return new SubscribeDemWorkflowsApi(undefined, apiBasePath);
}

export function GetDemWorkflows(){
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return new GetWorkflowsApi(undefined, apiBasePath);
}

export function GetDemWorkflowTriggerConditions(){
    const apiBasePath = `${getAppSetting('apiUrl')}/jwt`;
    return new GetWorkflowTriggerConditionsApi(undefined, apiBasePath);
}
