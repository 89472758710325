import {ColumnLayout, FormField, Select, SelectProps} from "@amzn/awsui-components-react";
import React, {useState, useEffect} from "react";
import WorkflowContent from "../workflowUtils/WorkflowContent";
import {GetDemWorkflows} from "../../dem-api/DemApiFactory";
import {DropdownStatusProps} from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status/interfaces";
import {WorkflowCategory} from "../../dem-api/generated-src";
import {SimFolder} from "../../dem-api/generated-src";
import DemApiFactory from "../../dem-api/DemApiFactory";


const SUBSCRIBABLE_WORKFLOW_CATEGORY = "Subscribable";
const DROPDOWN_STATUS_TYPE_LOADING = "loading";
const DROPDOWN_STATUS_TYPE_FINISHED = "finished";
const DROPDOWN_STATUS_TYPE_ERROR = "error";
const WORKFLOWS_LOADING_TEXT_MESSAGE = "Loading workflows...";
const SIM_FOLDERS_LOADING_TEXT_MESSAGE = "Loading sim folders...";
const WORKFLOWS_LOADING_FAILURE_TEXT_MESSAGE = "An error occurred fetching workflows.";
const SIM_FOLDERS_LOADING_FAILURE_TEXT_MESSAGE = "An error occurred while fetching sim folders.";
const WORKFLOWS = "workflows";
const ERROR = "error";
const NO_WORKFLOWS_PRESENT_MESSAGE = "No subscribable workflows present";
export default function SubscribableWorkflowPage() {
    // Stores the state for all sim folders
    const [simFolders, setSimFolders] = useState<SimFolder[]>();
    // Stores the state for selected SIM folder
    const [
        selectedSIMFolder,
        setSelectedSIMFolder
    ] = useState<SelectProps.Option>({});
    const demGetFoldersApi = DemApiFactory();
    useEffect(() => {
        (async () => {
            try {
                setSimFolders((await (demGetFoldersApi.getSimFolders())).data);
                setSimFoldersFetchingStatus(DROPDOWN_STATUS_TYPE_FINISHED);
            } catch (error) {
                setSimFoldersFetchingStatus(DROPDOWN_STATUS_TYPE_ERROR);
                setSimFoldersErrorText(SIM_FOLDERS_LOADING_FAILURE_TEXT_MESSAGE);
            }
        })();
    }, []);

    // Stores the state for selected workflow type
    const [
        selectedWorkflowType,
        setSelectedWorkflowType
    ] = useState<SelectProps.Option>({});

    // stores the state for selected workflow properties
    const [
        selectedWorkflowProperties,
        setSelectedWorkflowProperties
    ] = useState({});

    // stores the state for text to be shown while an error occurred fetching workflows
    const [
        workflowsErrorText,
        setWorkflowsErrorText
    ] = useState<string>("");

    // stores the state for text to be shown no workflows are fetched
    const [
        workflowsEmptyText,
        setWorkflowsEmptyText
    ] = useState<string>("");

    // stores the state for text to be shown while an error occurred fetching sim folders
    const [
        simFoldersErrorText,
        setSimFoldersErrorText
    ] = useState<string>("");

    // stores the state for text to be shown while an error occurred fetching workflows
    const [
        selectWorkflowTypesIsDisabled,
        setSelectWorkflowTypesIsDisabled
    ] = useState(true);

    // stores the state for status of fetching workflows
    const [
        workflowsFetchingStatus,
        setWorkflowsFetchingStatus
    ] = useState<DropdownStatusProps.StatusType>(DROPDOWN_STATUS_TYPE_LOADING);

    // stores the state for status of fetching sim folders
    const [
        simFoldersFetchingStatus,
        setSimFoldersFetchingStatus
    ] = useState<DropdownStatusProps.StatusType>(DROPDOWN_STATUS_TYPE_LOADING);

    // stores the state for subscribable workflows options to be shown on UI
    const [
        subscribableWorkflowTypes,
        setSubscribableWorkflowTypes
    ] = useState<SelectProps.Options>();

    // stores the state for subscribable DEM workflows
    const [
        subscribableDemWorkflows,
        setSubscribableDemWorkflows
    ] = useState<WorkflowCategory[]>([]);

    /**
     * set the state for types of subscribable workflows
     */
    useState(() => {
        void (async () => {
            setSubscribableWorkflowTypes(await parseSubscribableWorkflowCategories());
        })();
    });

    /**
     * Sets the selected sim folder and enables the selection of workflow types from drop down options
     * @param selectedSimFolder
     */
    function setSimFolderSelectedOption(selectedSimFolder: SelectProps.Option) {
        setSelectedSIMFolder(selectedSimFolder);
        setSelectWorkflowTypesIsDisabled(false);
    }

    /**
     * Gets the subscribable DEM workflows and returns them in parsed form of SelectProps.Options which is shown on UI
     */
    async function parseSubscribableWorkflowCategories() {
        const subscribableWorkflows: SelectProps.Option[] = [];
        try {
            const DemWorkflowsApi = GetDemWorkflows();
            const demWorkflowsResponse = (await DemWorkflowsApi.getWorkflows(SUBSCRIBABLE_WORKFLOW_CATEGORY)).data;
            let demWorkflows = demWorkflowsResponse[WORKFLOWS];
            let demWorkflowsResponseError = demWorkflowsResponse[ERROR];
            if (demWorkflowsResponseError !== ""){
                setWorkflowsFetchingStatus(DROPDOWN_STATUS_TYPE_ERROR);
                setWorkflowsErrorText(demWorkflowsResponseError);
                return subscribableWorkflows;
            }
            setWorkflowsFetchingStatus(DROPDOWN_STATUS_TYPE_FINISHED);
            if (demWorkflows.length === 0) {
                setWorkflowsEmptyText(NO_WORKFLOWS_PRESENT_MESSAGE)
                return subscribableWorkflows;
            }
            setSubscribableDemWorkflows(demWorkflows);
            demWorkflows.forEach((demWorkflow) => {
                subscribableWorkflows.push({
                    label: demWorkflow.workflowName,
                    value: "DWF_" + demWorkflow.workflowName?.split(" ").join(""),
                    description: demWorkflow.workflowDescription
                });
            });
            return subscribableWorkflows;
        } catch (error) {
            setWorkflowsFetchingStatus(DROPDOWN_STATUS_TYPE_ERROR);
            setWorkflowsErrorText(WORKFLOWS_LOADING_FAILURE_TEXT_MESSAGE);
            return subscribableWorkflows;
        }

    }

    /**
     * Sets the workflow details based on passed-in selected workflow option
     * @param selectedWorkflowOption Reference to the selected workflow option
     */
    function setSelectedWorkflowDetails(selectedWorkflowOption: SelectProps.Option) {
        setSelectedWorkflowType(selectedWorkflowOption);
        let selectedWorkflowIndex = subscribableDemWorkflows.findIndex(workflow => workflow.workflowName === selectedWorkflowOption.label);
        let workflowName = subscribableDemWorkflows[selectedWorkflowIndex].workflowName;
        let workflowDescription = subscribableDemWorkflows[selectedWorkflowIndex].workflowDescription;
        let workflowDefaultTriggerId = subscribableDemWorkflows[selectedWorkflowIndex].workflowDefaultTriggerId;
        let selectedWorkflowDetails = {
            "workflowName": workflowName,
            "workflowDescription": workflowDescription,
            "workflowDefaultTriggerId": workflowDefaultTriggerId,
            "folderIdForWorkflowSubscription": selectedSIMFolder.value
        }
        setSelectedWorkflowProperties(selectedWorkflowDetails)
    }

    return (
        <ColumnLayout borders="horizontal">
            <h2>Create Configurable Workflows</h2>
            <ColumnLayout columns={4}>
                <div>
                    <FormField
                        label="Choose SIM Folder"
                    >
                        <Select
                            selectedOption={selectedSIMFolder}
                            onChange={({detail}) =>
                                setSimFolderSelectedOption(detail.selectedOption)
                            }
                            options={simFolders?.map(item => ({
                                label: item.label,
                                value: item.value
                            }))}
                            selectedAriaLabel="Selected"
                            loadingText={SIM_FOLDERS_LOADING_TEXT_MESSAGE}
                            errorText={simFoldersErrorText}
                            statusType={simFoldersFetchingStatus}
                        />
                    </FormField>
                </div>
                <div>
                    <FormField
                        label="Choose Workflow Type"
                    >
                        <Select
                            selectedOption={selectedWorkflowType}
                            onChange={({detail}) =>
                                setSelectedWorkflowDetails(detail.selectedOption)
                            }
                            // Value in this dropdown should be Workflow type constant in Workflow_Trigger_Mapping_Store table
                            options={subscribableWorkflowTypes}
                            loadingText={WORKFLOWS_LOADING_TEXT_MESSAGE}
                            errorText={workflowsErrorText}
                            empty={workflowsEmptyText}
                            statusType={workflowsFetchingStatus}
                            selectedAriaLabel="Selected"
                            disabled={selectWorkflowTypesIsDisabled}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
            <div>
                {(() => {
                    if (selectedWorkflowType.value) {
                        return <WorkflowContent data={selectedWorkflowProperties}/>
                    }
                })()}
            </div>
        </ColumnLayout>
    );
}
