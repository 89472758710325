import {
  Badge,
  Box,
  Button,
  ColumnLayout,
  Grid,
  Header,
  Icon,
  Modal,
  ProgressBar,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import StarPicker from "react-star-picker";
import { BsFillBookmarkFill } from "react-icons/bs";
import React, { useEffect } from "react";
import Input from "@amzn/awsui-components-react/polaris/input";

const input_data = {
  service_details: {
    service_region: "FE",
    service_friendly_name: "crs",
  },
  attribute: "exception_signature"
};
const input_data_add_comment = {
                               configuration_details: {
                                           TicketOperationsConstruct: {
                                             Type_of_operation: "post_comment_on_ticket",
                                             cti: "Alexa/Operations/TestDem",
                                             message: "The migration is complete. We are good to resolve"
                                           }
                                       }
                               }
const output_data = {
  status: "success",
  response: {
    aggregated_records: {
      ExceptionSignature: {
        "DeviceStateNot-get@ADS3StateStore.java:118": 381739,
        "InvocationException-invoke@Unknown.java:54": 213562,
        "Source-AudioPlayer@NowPlayingHelper.java:208": 133814,
        "CardServiceClient$Exception.java:627": 65203,
        "ConversionException-getConverterTo@Unit.java:193": 55068,
      },
    },
    Title: "Aggregation of Exception Signature for crs",
    start_time: "2023-04-25 10:48:00",
    end_time: "2023-04-25 10:58:00",
  },
};

var interval = 0;
export default function TasksPage() {
  const [openModal, setModalOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [progressBarRunning, setProgressBarRunning] = React.useState(false);
  const [invokeButtonClicked, setInvokeButtonClicked] = React.useState(false);
  const [value, setValue] = React.useState(JSON.stringify(input_data));
  const [editable, setEditable] = React.useState(false);

  useEffect(() => {
    if (progressBarRunning) {
      interval = setInterval(() => {
        setProgress((prev) => prev + 1);
      }, 100);
    } else {
      clearInterval(interval);
    }
  }, [progressBarRunning]);

  useEffect(() => {
    if (progress === 100) {
      setProgressBarRunning(false);
      clearInterval(interval);
    }
  }, [progress]);

  const submitSuccess = () => {
    return (
      <div id="sucessDiv">
        {progress === 100 ? (
          <span>
            <Header variant="h2">Retrieved Output Json</Header>
            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <pre>{JSON.stringify(output_data, null, 2)}</pre>
            </div>
          </span>
        ) : (
          <></>
        )}
        {progressBarRunning ? (
          <ProgressBar
            value={progress}
            additionalInfo=""
            description="Output Json Retrieval In Progress"
            label=""
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div>
      <ColumnLayout borders="horizontal">
        <h2>Generic tasks available to reuse</h2>
        <div>
          <Grid
            gridDefinition={[
              { colspan: 0.5 },
              { colspan: 6 },
              { colspan: 4.5 },
            ]}
          >
            <div style={{ paddingTop: "4px" }}>
              <BsFillBookmarkFill size={16} color="orange" />
            </div>
            <div>
              <div>
                <SpaceBetween size="s" direction="horizontal">
                  <b style={{ fontSize: "medium" }}>
                    Fetch Atocha Aggregations
                  </b>
                  <StarPicker
                    // @ts-expect-error bad component typing
                    starRenderer={undefined}
                    disabled={false}
                    onChange={(value) => {}}
                    value={4}
                    halfStars={false}
                    numberStars={5}
                    size={12}
                  />
                </SpaceBetween>
              </div>
              <p>This construct fetches Atocha aggregation for an attribute for a service</p>
              <SpaceBetween size="xs" direction="horizontal">
                <Badge>Atocha aggregation</Badge>
                <Badge>Bar chart</Badge>
              </SpaceBetween>
            </div>
            <div style={{ padding: "5px" }}>
              <Button
                variant="primary"
                id="inputcti"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Test <Icon variant="normal" />
              </Button>
            </div>
            <Modal
              onDismiss={() => {
                setModalOpen(false);
                setInvokeButtonClicked(false);
              }}
              visible={openModal}
              closeAriaLabel="Close modal"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => {
                        setEditable(true);
                      }}
                    >
                      Modify
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setInvokeButtonClicked(true);
                        setProgressBarRunning(!progressBarRunning);
                      }}
                    >
                      Invoke
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              header="Test The Task"
            >
              <Header variant="h2">Input Json</Header>
              {!editable ? (
                <div>
                  <pre>{JSON.stringify(input_data, null, 2)}</pre>
                </div>
              ) : (
                <></>
              )}
              {editable ? (
                <textarea
                  id="modifyTextArea"
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  value={value}
                />
              ) : (
                <></>
              )}
              {invokeButtonClicked ? submitSuccess() : <></>}
            </Modal>
          </Grid>
        </div>
        <div>
        <Grid
                    gridDefinition={[
                      { colspan: 0.5 },
                      { colspan: 6 },
                      { colspan: 4.5 },
                    ]}
                  >
                    <div style={{ paddingTop: "4px" }}>
                      <BsFillBookmarkFill size={16} color="orange" />
                    </div>
                    <div>
                      <div>
                        <SpaceBetween size="s" direction="horizontal">
                          <b style={{ fontSize: "medium" }}>
                            Add comments to a ticket
                          </b>
                          <StarPicker
                            // @ts-expect-error bad component typing
                            starRenderer={undefined}
                            disabled={false}
                            onChange={(value) => {}}
                            value={3}
                            halfStars={false}
                            numberStars={5}
                            size={12}
                          />
                        </SpaceBetween>
                      </div>
                      <p>This construct adds comment on a ticket with ticket id and CTI mapping available</p>
                      <SpaceBetween size="xs" direction="horizontal">
                        <Badge>Add comment</Badge>
                        <Badge>Automation</Badge>
                      </SpaceBetween>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <Button
                        variant="primary"
                        id="inputcti"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        Test <Icon variant="normal" />
                      </Button>
                    </div>
                    <Modal
                      onDismiss={() => {
                        setModalOpen(false);
                        setInvokeButtonClicked(false);
                      }}
                      visible={openModal}
                      closeAriaLabel="Close modal"
                      footer={
                        <Box float="right">
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button
                              variant="link"
                              onClick={() => {
                                setEditable(true);
                              }}
                            >
                              Modify
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setInvokeButtonClicked(true);
                                setProgressBarRunning(!progressBarRunning);
                              }}
                            >
                              Invoke
                            </Button>
                          </SpaceBetween>
                        </Box>
                      }
                      header="Test The Task"
                    >
                      <Header variant="h2">Input Json</Header>
                      {!editable ? (
                        <div>
                          <pre>{JSON.stringify(input_data_add_comment, null, 2)}</pre>
                        </div>
                      ) : (
                        <></>
                      )}
                      {editable ? (
                        <textarea
                          id="modifyTextArea"
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          value={value}
                        />
                      ) : (
                        <></>
                      )}
                      {invokeButtonClicked ? submitSuccess() : <></>}
                    </Modal>
                  </Grid>
        </div>
        <div>
        <Grid
                    gridDefinition={[
                      { colspan: 0.5 },
                      { colspan: 6 },
                      { colspan: 4.5 },
                    ]}
                  >
                    <div style={{ paddingTop: "4px" }}>
                      <BsFillBookmarkFill size={16} color="orange" />
                    </div>
                    <div>
                      <div>
                        <SpaceBetween size="s" direction="horizontal">
                          <b style={{ fontSize: "medium" }}>
                            Cut a tracker ticket to CTI
                          </b>
                          <StarPicker
                            // @ts-expect-error bad component typing
                            starRenderer={undefined}
                            disabled={false}
                            onChange={(value) => {}}
                            value={3}
                            halfStars={false}
                            numberStars={5}
                            size={12}
                          />
                        </SpaceBetween>
                      </div>
                      <p>This construct cuts a ticket to a specified CTI </p>
                      <SpaceBetween size="xs" direction="horizontal">
                        <Badge>Ticket cutting</Badge>
                        <Badge>on-call</Badge>
                      </SpaceBetween>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <Button
                        variant="primary"
                        id="inputcti"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        Test <Icon variant="normal" />
                      </Button>
                    </div>
                    <Modal
                      onDismiss={() => {
                        setModalOpen(false);
                        setInvokeButtonClicked(false);
                      }}
                      visible={openModal}
                      closeAriaLabel="Close modal"
                      footer={
                        <Box float="right">
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button
                              variant="link"
                              onClick={() => {
                                setEditable(true);
                              }}
                            >
                              Modify
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setInvokeButtonClicked(true);
                                setProgressBarRunning(!progressBarRunning);
                              }}
                            >
                              Invoke
                            </Button>
                          </SpaceBetween>
                        </Box>
                      }
                      header="Test The Task"
                    >
                      <Header variant="h2">Input Json</Header>
                      {!editable ? (
                        <div>
                          <pre>{JSON.stringify(input_data, null, 2)}</pre>
                        </div>
                      ) : (
                        <></>
                      )}
                      {editable ? (
                        <textarea
                          id="modifyTextArea"
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          value={value}
                        />
                      ) : (
                        <></>
                      )}
                      {invokeButtonClicked ? submitSuccess() : <></>}
                    </Modal>
                  </Grid>
        </div>
        <div>
        <Grid
                    gridDefinition={[
                      { colspan: 0.5 },
                      { colspan: 6 },
                      { colspan: 4.5 },
                    ]}
                  >
                    <div style={{ paddingTop: "4px" }}>
                      <BsFillBookmarkFill size={16} color="orange" />
                    </div>
                    <div>
                      <div>
                        <SpaceBetween size="s" direction="horizontal">
                          <b style={{ fontSize: "medium" }}>
                            Can alter the status of the ticket
                          </b>
                          <StarPicker
                            // @ts-expect-error bad component typing
                            starRenderer={undefined}
                            disabled={false}
                            onChange={(value) => {}}
                            value={4}
                            halfStars={false}
                            numberStars={5}
                            size={12}
                          />
                        </SpaceBetween>
                      </div>
                      <p>This construct changes the status of a ticket</p>
                      <SpaceBetween size="xs" direction="horizontal">
                        <Badge>Ticket status</Badge>
                        <Badge>Automation</Badge>
                      </SpaceBetween>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <Button
                        variant="primary"
                        id="inputcti"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        Test <Icon variant="normal" />
                      </Button>
                    </div>
                    <Modal
                      onDismiss={() => {
                        setModalOpen(false);
                        setInvokeButtonClicked(false);
                      }}
                      visible={openModal}
                      closeAriaLabel="Close modal"
                      footer={
                        <Box float="right">
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button
                              variant="link"
                              onClick={() => {
                                setEditable(true);
                              }}
                            >
                              Modify
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setInvokeButtonClicked(true);
                                setProgressBarRunning(!progressBarRunning);
                              }}
                            >
                              Invoke
                            </Button>
                          </SpaceBetween>
                        </Box>
                      }
                      header="Test The Task"
                    >
                      <Header variant="h2">Input Json</Header>
                      {!editable ? (
                        <div>
                          <pre>{JSON.stringify(input_data, null, 2)}</pre>
                        </div>
                      ) : (
                        <></>
                      )}
                      {editable ? (
                        <textarea
                          id="modifyTextArea"
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          value={value}
                        />
                      ) : (
                        <></>
                      )}
                      {invokeButtonClicked ? submitSuccess() : <></>}
                    </Modal>
                  </Grid>

        </div>
      </ColumnLayout>
    </div>
  );
}
